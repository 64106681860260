import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommenService } from './common.service';
import { MainService } from './main.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AdBlockPopupComponent } from './notification-banner/notification-banner.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AdBlockPopupComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})

export class AppComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  constructor(private _commenservice: CommenService, private mainService: MainService) {
    // this.themeService.mdTabSelected = 'theme-dark';
  }
  ngOnInit(): void {
  }
}
